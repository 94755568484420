export const FormsState = {
    error: false,
    payload: [],
    message: '',
    modal:false,
    modal1:false,
    modal2:false,
    changed:false,
    formToFill:[],
    formResponse:'',
    modal3:false
  };
  
export const MessageState = {
  mobileListScreen: true,
  initalLoading:true,
  error: false,
  payload: [],
  message: '',
  uuid:'',
  receiverAvatar:'',
  phoneNumber: '',
  receiverDisplayName:'',
  userList:'',
  userListModal:false,
  setRecevierUUID:''
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionCreator } from '../../reducers/actionCreator';
import { Avatar, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Logo from '../../assets/images/fossilmd.png';
const Header = props => {
  const [profileLogo, setProfileLogo] = useState('');
  const [hospitalLogo, setHospitalLogo] = useState('');
  useEffect(() => {
    let hospital = JSON.parse(localStorage.getItem('user_data'));
    setProfileLogo(hospital?.profile_image || '');
    setHospitalLogo(hospital?.hospital?.logo?.path || '');
    props.fetchHospital({ userTypeId: 2, page: 1, limit: 200 });
  }, []);

  return (
    <div className="dashboard__header">
      <div className="title">
        {/* <h3><Link to="/">{localStorage.getItem('name') || 'Login'}</Link></h3> */}
        <h3>
          <Link to="/dashboard">
            <img className="header-logo" src={Logo} />
          </Link>
        </h3>
      </div>
      <div className="logoutBlock">
        <span>
          <Avatar
            style={{ backgroundColor: '#f0f5f1' }}
            size={40}
            src={
              profileLogo
                ? `${profileLogo}`
                : hospitalLogo
                ? hospitalLogo
                : `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${localStorage.getItem(
                    'name',
                  )}`
            }
          />
          <Link to="/logout">
            {' '}
            <i className="fas fa-sign-out-alt pr2 pl2" />
            Logout
          </Link>
        </span>
      </div>
    </div>
  );
};

const mapStoreToProps = ({ Hospital }) => {
  return {
    hospital: Hospital.payload,
  };
};
const mapDispatchToProps = dispatch => ({
  fetchHospital: param =>
    dispatch(actionCreator({ method: 'GET', action_type: 'CHECK_HOSPITAL', param })),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Header);

/**
 * Initital state for list of branch
 */
export const BranchState = {
  error: false,
  payload: [],
  message: '',
  modal: false,
  modal1: false,
  changed: false,
};

/**
 * Initital state for list of branch
 */
export const AppointmentState = {
    error: false,
    payload: [],
    view:[],
    message: '',
    modal: false,
    modal1: false,
    modal2:false,
    modal3:false,
    changed: false,
  };

import { SummaryMessageState } from '../ComponentState/summary_message';
import _ from 'lodash';
/**
 * @param state
 * @param action
 */

export const SummaryMesssageReducer = (state = SummaryMessageState, action) => {
  let message;
  switch (action.type) {
    case 'SET_CURRENT_CHAT_USERNAME':
      return { ...state, userName: action.payload };
    case 'SET_CURRENT_CHAT_USERPHONENUMBER':
      return { ...state, userPhoneNumber: action.payload };

    case 'SEARCH_CHAT_USER':
      let payloadRes = [...state.payload];
      // Checks whether reciever display name or sender display available. and also filtering by messages
      const filteredPayload = payloadRes?.filter(
        cur =>
          (cur?.receiverDisplayName
            ? cur?.receiverDisplayName.toLowerCase().includes(action.payload?.toLowerCase())
            : cur?.senderDisplayName.toLowerCase().includes(action.payload?.toLowerCase())) ||
          cur?.message?.toLowerCase().includes(action.payload?.toLowerCase()),
      );
      return { ...state, filterPayload: filteredPayload, searchField: action.payload };

    case 'SET_LATEST_MESSAGE_SUMMARY':
      const check = state.payload.filter(
        (result, i) => result.conversationId === action.payload.conversationId,
      ).length;
      if (check === 0) {
        state.payload = [...state.payload, action.payload];
      }
      console.log('message summary payload', state.payload);
      console.log('my-payload', action.payload);
      message = state.payload.map((result, i) => {
        if (result.conversationId === action.payload.conversationId) {
          console.log('the-result', state);

          action.payload.receiverAvatar = result.receiverAvatar;
          action.payload.receiverDisplayName = result.receiverDisplayName
            ? result.receiverDisplayName
            : state.userName;
          action.payload.receiverPhoneNumber = result.receiverPhoneNumber
            ? result.receiverPhoneNumber
            : state.userPhoneNumber;
          action.payload.senderPhoneNumber = result.senderPhoneNumber
            ? result.senderPhoneNumber
            : state.userPhoneNumber;
          i = i + 1;
          return action.payload;
        } else {
          return result;
        }
      });

      message.sort((a, b) => b.id - a.id);

      const updateFilterPayload = () => {
        const filterPayloadClone = [...state.filterPayload];
        if (state.searchField.length > 0) {
          return filterPayloadClone.map(cur =>
            message[0].conversationId === cur.conversationId ? (cur = message[0]) : cur,
          );
        }
      };

      return {
        ...state,
        error: action.error,
        payload: message,
        filterPayload: state.searchField.length === 0 ? message : updateFilterPayload(),
        message: action.message,
        changed: true,
      };
    case 'SET_MESSAGE_SUMMARY':
      message = action.payload;
      message.sort((a, b) => b.id - a.id);

      return {
        ...state,
        error: action.error,
        payload: message,
        message: action.message,
        filterPayload: message,
        // filterPayload: state.searchField.length === 0 && message,
        changed: true,
      };
    case 'SET_LATEST_INCOMING_MESSAGE_SUMMARY':
      console.log('message summary payload', state.payload);

      let checkConversation = state.payload.filter(
        (result, i) => result.conversationId === action.payload.conversationId,
      ).length;

      console.log('Incoming length', checkConversation);
      if (checkConversation > 0) {
        message = state.payload.map((result, i) => {
          if (result.conversationId === action.payload.conversationId) {
            return { ...result, ...action.payload };
          } else {
            return result;
          }
        });
      } else {
        state.payload = [...state.payload, action.payload];
        message = state.payload.map((result, i) => {
          if (result.conversationId === action.payload.conversationId) {
            return action.payload;
          } else {
            return result;
          }
        });
      }

      message.sort((a, b) => b.id - a.id);

      return {
        ...state,
        error: action.error,
        payload: message,
        filterPayload: state.searchField.length === 0 ? message : state.filterPayload,
        // filterPayload: state.searchField.length === 0 && message,
        message: action.message,
        changed: true,
      };
    default:
      return state;
  }
};

import { CampaignState } from '../ComponentState/campaign';
import { message } from 'antd';

export const CampaignReducer = (state = CampaignState, action) => {
  switch (action.type) {
    case 'CREATE_CAMPAIGN':
      message.success('CAMPAIGN CREATED SUCCESSFULLY');
      return { 
        ...state,
        error: action.error,
        message: action.message,
        modal: false,
        changed: true 
      };
    case 'EDIT_CAMPAIGN':
      return {
        ...state,
        error: action.error,
        payload: action.payload,
        message: action.message,
        changed: true,
      };
    case'FETCH_CAMPAIGN':
      return {
        ...state,
        error: action.error,
        payload: action.payload,
        message: action.message,
        changed: false,
      };
    case 'FILTER_CAMPAIGN':
      return { 
        ...state,
        filterType: action.payload
      };
    case 'DELETE_CAMPAIGN':
      message.success('BRANCH DELETED SUCCESSFULLY');
      return {
        ...state,
        error: action.error,
        message: action.message,
        changed: true 
      };
    case 'SCHEDULE_CAMPAIGN': 
      message.success('CAMPAIGN SCHEDULED SUCCESSFULLY')
      return {
        ...state,
        modal2: false,
        changed: true
      }
    case 'OPEN_CREATE_CAMPAIGN_MODAL':
      return { 
        ...state,
        modal: true 
      };
    case 'CLOSE_CREATE_CAMPAIGN_MODAL':
      return {
        ...state,
        modal: false 
      };
    case 'OPEN_EDIT_CAMPAIGN_MODAL':
      return {
        ...state,
        modal1: true 
      };
    case 'CLOSE_EDIT_CAMPAIGN_MODAL':
      return {
        ...state,
        modal1: false 
      };
    case 'OPEN_SCHEDULE_CAMPAIGN_MODAL':
      return {
        ...state,
        modal2: true 
      };
    case 'CLOSE_SCHEDULE_CAMPAIGN_MODAL':
      return {
        ...state,
        modal2: false 
      };
    default:
      return state;
  }
};

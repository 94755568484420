export const TemplateState = {
    error: false,
    payload: [],
    message: '',
    modal:false,
    modal1:false,
    modal2:false,
    changed:false,
    templateActions:[]
  };
  
import { ModuleState } from '../ComponentState/modules';
import { message } from 'antd';

/**
 * @param state
 * @param action
 */

export const ModuleReducer = (state = ModuleState, action) => {
  switch (action.type) {
    case 'FETCH_MODULES':
      console.log('FETCH', action.payload);
      return {
        ...state,
        error: action.error,
        payload: action.payload.rows,
        message: action.message,
        changed: false,
      };

    default:
      return state;
  }
};

export const LandingPageState = {
  error: false,
  payload: [],
  features: [],
  message: '',
  packages: [],
  modal: false,
  modal1: false,
  changed: false,
};

import callApi from '_utils/callApi';
import { backend_api } from '../_constants/api';
import { message } from 'antd';
// import { randomKey } from  '../_utils';


/**
 * Async action creator for hospital. All the action to roledef reducer are dispatch from this.
 * @param action
 */
export const actionCreator = result => {

  return async dispatch => {
    let url = backend_api[result.action_type];
    if (result.id) {
      if (Array.isArray(result.id)) {
        url = `${url}/${result.id.join('/')}`
      } else {
        url = `${url}/${result.id}`;
      }
    }

    console.log({ Action : result});
    console.log({ 'Api url' : url});

    let query = '';
    if (result.param) {
      if (Array.isArray(result.param.provider_id)) {
        const { provider_id, ...rest } = result.param;
        const checkParam = Object.keys(provider_id).map(
          k => encodeURIComponent('provider_id') + '=' + encodeURIComponent(provider_id[k]),
        );
        if (checkParam.length > 0) {
          query += checkParam.join('&') + '&';
        }
        query += Object.keys(rest)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(result.param[k]))
          .join('&');
      } else {
        query += Object.keys(result.param)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(result.param[k]))
          .join('&');
      }
    }

    if (query) {
      url = `${url}?${query}`;
    }


    let headers = {};
    if (result.contentType) {
      headers = {
        ...headers,
        'Content-Type': 
          result.contentType === 'JSON' ? 'application/json' : ''
      }
    }

    if (localStorage.getItem('token')) {
      headers = {
        ...headers,
        Authorization: localStorage.getItem('token')
      }
    }

    try {
      const config = {
        method: result.method || 'GET',
        headers: headers,
        body: result.contentType 
          === 'JSON' ? JSON.stringify(result.values) : result.values,
      };
      const response = await callApi(url, config);
      if (response.success) {

        console.log({ 'Api success': response.Data || response.DATA || response , 'url' : url});

        if (result.action_type === 'USER_LOGIN') {
          if (response.user.userTypeId == 2) {
            return dispatch({
              type: result.action_type,
              payload: { user: response.user, token: response.token },
              error: '',
            });
          } else {
            message.error('This is the login portal of hospital')
          }
        } else {
          return dispatch({
            type: result.action_type,
            payload: response.data || response.DATA || response,
            error: '',
            message: response.message
          });
        }
      } else {
        console.log('noooooo')
      }
    } catch (err) {
        
      console.log({ 'API_ERROR': err })

      message.error(err.message);
    }
  };
};

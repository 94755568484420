export const CampaignState = {
    error: false,
    payload: [],
    message: '',
    filterType: 'all',
    modal:false,
    modal1:false,
    modal2:false,
    changed:false
  };
  
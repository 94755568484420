import { LoginState } from '../ComponentState/login';
import { message } from 'antd';

/**
 * @param state
 * @param action
 */

export const LoginReducer = (state = LoginState, action) => {
  switch (action.type) {
    case 'USER_LOGIN':

      const { user, token } = action.payload; 
      
      localStorage.setItem('token', token);
      localStorage.setItem('name', user.hospital.fullName);
      localStorage.setItem('user_data', JSON.stringify(user));
      localStorage.setItem('hospital_id', user.hospitalId);

      message.success('LOGGED IN SUCCESSFULLY');
      return {
        error: action.error,
        payload: action.payload,
        isLogin: true,
        message: action.message,
      };
    case 'USER_LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('user_data');
      localStorage.removeItem('hospital_id');

      message.success('LOGGED OUT SUCCESSFULLY');
      window.location.href = '/login'
      return {
        error: action.error,
        isLogin: false,
        payload: action.payload,
        message: action.message,
      };
    case 'LOGIN_VERIFIED':
      return { error: action.error, payload: action.payload, isLogin: true };
    case 'LOGIN_EXPIRED':
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('user_data');
      localStorage.removeItem('hospital_id');
      return { error: action.error, payload: action.payload, isLogin: false };
    default:
      return state;
  }
};

/**
 * Initital state for list of branch
 */
export const PaymentState = {
    error: false,
    payload: [],
    view:[],
    message: '',
    modal: false,
    modal1: false,
    modal2:false,
    changed: false,
    payment: '',
    payments: [],
    initializePayment: {},
  };

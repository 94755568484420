import { BrainTreeTokenState } from '../ComponentState/braintree_token';
import { message } from 'antd';

/**
 * @param state
 * @param action
 */

export const BrainTreeTokenReducer = (state = BrainTreeTokenState, action) => {
  switch (action.type) {
    case 'FETCH_BRAINTREE_TOKEN':
      console.log('FETCH', action.payload);
      return {
        ...state,
        error: action.error,
        payload: action.payload,
        message: action.message,
        changed: false,
      };
    default:
      return state;
  }
};

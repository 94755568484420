import { PaymentState } from '../ComponentState/payment';
import { message } from 'antd';
import moment from 'moment';
/**
 * @param state
 * @param action
 */

const status_to_title = {
  pending: 'Pending',
  confirmed: 'Confirmed',
  cancelled: 'Cancelled',
  completed: 'Completed',
};
export const PaymentReducer = (state = PaymentState, action) => {
  switch (action.type) {
    case 'OPEN_EDIT_PAYMENT_MODAL':
      return {
        ...state,
        modal1: true,
      };
    case 'CLOSE_EDIT_PAYMENT_MODAL':
      return {
        ...state,
        modal1: false,
      };

    case 'OPEN_VIEW_PAYMENT_MODAL':
      return {
        ...state,
        modal2: true,
      };
    case 'CLOSE_VIEW_PAYMENT_MODAL':
      return {
        ...state,
        modal2: false,
        payment: ''
      };
    case 'FETCH_PAYMENTS':
      return {
        ...state,
        error: action.error,
        message: action.message,
        payments: action.payload,
        changed: false
      };
    case 'FETCH_PAYMENT_BY_ID':
      return {
        ...state,
        payment: action.payload
      }
    case 'ADD_PAYMENT':
      message.success('PAYMENT ADDED SUCCESSFULLY')
      return {
        ...state,
        error: action.error,
        message: action.message,
        changed: true,
      };
    case 'EDIT_PAYMENT':
      message.success('PAYMENT UPDATED SUCCESSFULLY')
      return {
        ...state,
        modal1: false,
        error: action.error,
        message: action.message,
        changed: true,
      }
    case 'UPDATE_PAYMENT_STATUS':
      message.success('PAYMENT STATUS UPDATED SUCCESSFULLY');
      return {
        ...state,
        error: action.error,
        message: action.message,
        changed: true
      }
    case 'INITIALIZE_PAYMENT':
      return {
        ...state,
        error: action.error,
        message: action.message,
        initializePayment: action.payload,
      };
    case 'CONFIRM_PAYMENT':
      return {
        ...state,
        error: action.error,
        message: action.message,
      };
    case 'STATUS_CHANGE_PAYMENT':
      message.success('STATUS CHANGED SUCCESSFULLY');
      return {
        ...state,
        error: action.error,
        message: action.message,
        changed: true,
      };
    case 'FETCH_ERROR':
      message.error('Ooops , Iam from payment');
      return {
        ...state,
      };
    default:
      return state;
  }
};
